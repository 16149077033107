import vtt from '../assets/vtt.jpeg'
import cascade from '../assets/cascade.jpeg'
import monastere from '../assets/monastere.jpeg'
import zoo from '../assets/zoo.jpeg'


const activites = [
    {
        titre: 'vtt',
        image: vtt
    },
    {
        titre: 'cascade',
        image: cascade
    },
    {
        titre: 'monastere',
        image: monastere
    },
    {
        titre: 'zoo',
        image: zoo
    }

]

export default activites;